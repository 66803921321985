.exception {
    display: flex;
    align-items: center;
    height: 80%;
    min-height: 500px;
}

.imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
 }

.imgEle {
    float: right;
    width: 100%;
    max-width: 430px;
    height: 360px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.content {
    flex: auto;
}

.content h1 {
    margin-bottom: 24px;
    color: #434e59;
    font-weight: 600;
    font-size: 72px;
    line-height: 72px;
}

.desc {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
}
