* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html body {
  height: 100%;
  font-family: "Helvetica Neue", "Helvetica", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.options-drawer .ant-drawer-title,
.options-drawer .ant-drawer-close,
.options-drawer .ant-drawer-body,
.options-drawer .ant-typography,
.options-drawer .ant-checkbox-wrapper,
.options-drawer .ant-tree-switcher {
    color: var(--dark-color);
}

.options-drawer .ant-drawer-close:hover {
  color: var(--main-color);
}
