.preload-img {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 100px);
    width: 200px;
    animation: pulse 2s linear infinite;
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
}