:root {
    --main-color: #7FD4B4;
    --accent-color: #58d7c5;
    --light-color: #E7F6F8;
    --dark-color: #2C343C;
    --dark-color-2: #2f9182;
    --light-color-2: #CCEBEE;
    --secondary-color: #93DCAC;
    --antd-wave-shadow-color: var(--accent-color);
}

.ant-layout {
    background: #fff;
}

.container-sider {
    height: 100vh;
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0,21,41,.35);
    /* background: rgb(231,246,248);
    background: linear-gradient(180deg, rgba(231,246,248,1) 0%, rgba(102,204,165,1) 100%); */
    background: rgb(231,246,248);
    background: linear-gradient(180deg, rgba(231,246,248,1) 0%, rgba(132,220,180,1) 50%, rgba(55,169,152,1) 100%);
    overflow-x: auto;
    overflow-y: hidden;
    position: fixed;
    left: 0;
}

.ant-layout-sider-children .container {
    background: transparent;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.ant-layout-sider-children .container .ant-avatar {
    min-width: 32px;
}

.ant-layout-sider-children .container .dropdown-container:last-child {
    padding: 0 8px 0 4px;
}

.menu-container {
    min-height: calc(100vh - 144px) !important;  
}

.menu-sider {
    margin: 10px 0 4px 0;
    background: transparent;
    color: var(--dark-color);
    max-height: calc(100vh - 275px);
    overflow-x: hidden;
    overflow-y: auto;
}

.menu-sider .ant-menu-item > a,
.menu-sider .ant-menu-item-selected > a span,
.menu-sider .ant-menu-item > a .anticon,
.menu-sider .ant-menu-submenu-title { color: var(--dark-color) !important; font-size: 15px; }

.menu-sider .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.menu-sider .ant-menu-submenu-title .ant-menu-submenu-arrow::after { background: var(--dark-color); }

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon { margin-right: 12px; }

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: rgba(255, 255, 255, 0.2);
    filter: contrast(150%);
    padding: 6px 0;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu.ant-menu-dark .ant-menu-item:not(.ant-menu-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.logo {
    position: relative;
    height: 72px;
    overflow: hidden;
    line-height: 52px;
    box-shadow: inset 0 10px 10px -10px rgba(0,0,0,0.2);
    background: rgba(255, 255, 255, 0);
    transition: all .3s;
    z-index: 900;
}

.logo img {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
    position: relative;
    top: 10px;
    left: 50%;
}

.logo h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #eaeaea;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Avenir', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    vertical-align: middle;
}

.menu-container {
    min-height: calc(100vh - 136px);
    position: relative;
}

.logoLeasetic {
    position: absolute;
    bottom: 5px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}

.content {
    /* margin: 16px 24px 24px 280px; */
    margin: 0 0 0 256px;
}

.footer {
    text-align: center;
    margin-left: 256px;
    padding: 14px 12px 12px;
    background: rgb(255, 255, 255);
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.footer p { margin-bottom: 0; }
.footer a { color: var(--dark-color-2) }

.ant-btn:not(.ant-btn-icon-only) { border-radius: 50px; }

.ant-tree li .ant-tree-node-content-wrapper:hover {
    background: none;
}

.container {
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 4px;
}

.dropdown-container {
    height: 100%;
    cursor: pointer;
    display: inline-block;
    padding: 0 12px;
    transition: all .3s;
}

.dropdown-container:hover {
    background: rgba(0,0,0,.045);
}

.dropdown-container > i {
    font-size: 16px;
    transform: none;
}

.dropdown-container > i svg {
    position: relative;
    top: 3px;
}

.full-name {
    color: #434e59;
    font-weight: bold;
}

.role {
    font-size: 10px;
    color: #434e59;
}

.trigger {
    font-size: 20px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover, .ant-page-header-back-button:hover {
    color: var(--main-color);
}

.ant-btn-primary {
    color: #fff;
    border-color: var(--main-color);
    background-color: var(--main-color);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #fff;
    color: var(--main-color);
    border-color: var(--main-color);
}

.ant-btn-link, .ant-btn-link:hover,
.ant-pagination-prev:not(.ant-pagination-disabled), .ant-pagination-next:not(.ant-pagination-disabled), .ant-pagination-jump-prev:not(.ant-pagination-disabled), .ant-pagination-jump-next:not(.ant-pagination-disabled), .ant-pagination-item:not(.ant-pagination-disabled),
.ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-prev:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-prev:not(.ant-pagination-disabled) a, .ant-pagination-next:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-next:not(.ant-pagination-disabled) a, .ant-pagination-jump-prev:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-jump-prev:not(.ant-pagination-disabled) a, .ant-pagination-jump-next:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-jump-next:not(.ant-pagination-disabled) a, .ant-pagination-item:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination-item:not(.ant-pagination-disabled) a
{
    border: none;
}

.ant-pagination-item { font-size: 16px; }

.ant-pagination-item-active {
    font-weight: 600;
    font-size: 18px;
}

.ant-pagination-options-size-changer.ant-select .ant-select-selection,
.ant-pagination-options-size-changer.ant-select.ant-select-focused:not(.ant-select-disabled) .ant-select-selection,
.ant-pagination-options-size-changer.ant-select.ant-select-open:not(.ant-select-disabled) .ant-select-selection {
    border: none;
    box-shadow: none;
    background: transparent;
}

.ant-btn-link, .ant-table-tbody > tr > td .anticon:not(.anticon-delete), .ant-typography a {
    color: var(--dark-color);
}

.ant-btn-link:hover,
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):not(.ant-radio-button-wrapper-disabled):hover > span,
.ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover,
.ant-tabs-nav .ant-tabs-tab-active,
.ant-pagination-item:hover a, .ant-pagination-item:focus a, .ant-pagination-item-active a , .ant-pagination-item-active:hover a,
.ant-typography a:focus, .ant-typography a:hover,
.ant-breadcrumb-link a:hover,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
.ant-table-tbody > tr > td a {
    color: var(--dark-color-2);
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--main-color);
    background-color: var(--main-color);
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input:hover,
.ant-select:hover .ant-select-selection,
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox, .ant-checkbox-checked::after {
    border-color: var(--main-color);
}

.ant-select-selection--single .ant-select-selection__rendered { margin-right: 32px; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: var(--main-color);
    border-color: var(--main-color);
    box-shadow: -1px 0 0 0 var(--main-color);
}

.ant-radio-group:not(.ant-radio-group-solid) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-btn:not(.ant-btn-danger):not(.ant-btn-disabled):hover, .ant-btn:not(.ant-btn-danger):not(.ant-btn-disabled):focus,
.ant-pagination-item-active, .ant-pagination-item:hover, .ant-pagination-item:focus,
.ant-radio-group:not(.ant-radio-group-solid) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-group:not(.ant-radio-group-solid) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: var(--main-color);
    color: var(--main-color);
}

.ant-radio-button-wrapper > span { transition: color 0.3s; }

.ant-tabs-ink-bar,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before { background-color: var(--main-color) !important; }

.ant-tabs-nav .ant-tabs-tab { text-transform: uppercase; }

h1.ant-typography, h2.ant-typography, h3.ant-typography, h4.ant-typography {
    font-weight: 400;
    text-transform: uppercase;
}

h2.ant-typography {
    color: var(--dark-color);
}

h4.ant-typography {
    font-weight: 400;
    color: var(--dark-color);
    text-transform: uppercase;
}

.ant-card-body h4.ant-typography { text-transform: none; }

.ant-typography strong, .ant-table-thead > tr > th .ant-table-column-title { letter-spacing: 0.05em; }

.ant-typography strong {
    color: var(--dark-color-2);
    font-weight: 400;
} 

.ant-typography.timescale-update strong {
    display: inline-block;
    border: 1px solid var(--main-color);
    border-radius: 3px;
    padding: 2px 6px 1px;
}

.ant-table-thead > tr > th {
    background: #fff;
    color: var(--dark-color-2);
    font-weight: 600;
    text-transform: uppercase;
    transition: color 0.3s;
}

.ant-table-thead > tr > th:hover {
    background: #fff;
    color: var(--dark-color);
}

.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before,.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before { background: transparent; } 

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner { margin-top: -0.5em; }

.ant-page-header.has-breadcrumb { border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > .ant-btn,
.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > a,
.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > div > .ant-btn,
.ant-page-header.has-breadcrumb .ant-page-header-heading-extra > div > a {
    margin: 0 5px !important;
}

.ant-page-header.has-breadcrumb .ant-page-header-heading-title {
    color: var(--dark-color);
    font-weight: 400;
}

.ant-page-header.has-breadcrumb .ant-breadcrumb .ant-breadcrumb-link a,
.ant-page-header.has-breadcrumb .ant-breadcrumb .ant-breadcrumb-link span,
.ant-page-header.has-breadcrumb .ant-breadcrumb .ant-breadcrumb-separator {
    color: var(--dark-color);
    opacity: 0.6;
    text-transform: uppercase;
    transition: all 0.3s;
}

.ant-page-header.has-breadcrumb .ant-breadcrumb .ant-breadcrumb-link a:hover { opacity: 1; color: var(--dark-color-2); }

.ant-timeline-item-head-green { color: var(--main-color); border-color: var(--main-color); }
/* 
.ant-descriptions-row { display: flex; justify-content: space-between; } */

/*  .ant-descriptions-item { padding-right: 48px; }

.ant-descriptions-item > span { display: unset; } */

/* .ant-descriptions-row > th, .ant-descriptions-row > td {
    display: inline-block;
    margin-right: 16px;
} */

.ant-descriptions-row > th, .ant-descriptions-row > td {
    min-width: fit-content;
}

.ant-descriptions .ant-descriptions-title {
    font-size: 20px;
    font-weight: 400;
    color: var(--dark-color);
}

.ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.5) !important;
    text-transform: uppercase;
}

.ant-descriptions-item-content, .ant-descriptions-item-content .ant-typography {
    font-size: 1.1em !important;
    font-weight: 400 !important;
    color: var(--dark-color-2);
}

.ant-divider .ant-divider-inner-text {
    color: var(--dark-color-2);
    font-size: 18px;
}

.ant-divider .ant-divider-inner-text h2 {
    color: var(--main-color);
}

.ant-divider-horizontal::before, .ant-divider-horizontal::after {
    border-top: 1px solid var(--main-color) !important;
}


.ant-divider-horizontal.ant-divider-with-text-center::before, 
.ant-divider-horizontal.ant-divider-with-text-left::before, 
.ant-divider-horizontal.ant-divider-with-text-right::before, 
.ant-divider-horizontal.ant-divider-with-text-center::after, 
.ant-divider-horizontal.ant-divider-with-text-left::after, 
.ant-divider-horizontal.ant-divider-with-text-right::after {
    top: 0;
}

.ant-card { border-radius: 6px; }

.ant-card-bordered {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.ant-card-bordered .ant-card-head { border-color: rgba(0, 0, 0, 0.15); }

.ant-card-bordered .ant-card-head .ant-tabs-bar { border-color: transparent; }

.ant-card-head { border-radius: 6px 6px 0 0; }

.infolist-card { box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); }

.infolist-card .ant-card-head {
    background: #F0FAF5;
    color: var(--dark-color-2);
    text-transform: uppercase;
}

.infolist-card .ant-card-extra .anticon { color: var(--dark-color); }

.ant-page-header-heading-extra, .home-tabs .ant-tabs-nav, .home-tabs .ant-tabs-nav-wrap {
    max-width: calc(100vw - 320px);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #d7d7d7;
    height: 50px;
}

@media only screen and (max-width: 992px) {
    .content, .footer {
        margin-left: 80px;
    }

    .ant-page-header-heading-extra, .home-tabs .ant-tabs-nav, .home-tabs .ant-tabs-nav-wrap {
        max-width: calc(100vw - 140px);
    }

    .logoLeasetic {
        left: 8px;
        width: 70px;
        bottom: 0;
    }
}

@media only screen and (max-width: 576px) {
    .content, .footer {
        margin-left: 0;
    }

    .ant-page-header-heading-extra, .home-tabs .ant-tabs-nav, .home-tabs .ant-tabs-nav-wrap {
        max-width: 100vw;
    }
}
